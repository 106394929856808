const search_arr = [
  {
    title: `빈스 쇼핑몰`,
    text: `즐거운 온라인 쇼핑공간, 빈스 쇼핑몰에 오신걸 환영합니다. 퀄리티 짱~ 좋은 가방들만! 빈이 자매들이 직접 만드는 수제 가방 나라.`,
    loca: `https://www.bsbags.co.kr`,
  },
  {
    title: `원두커피 전문 쇼핑몰 로스팅빈스`,
    text: `안녕하세요 로스팅빈스입니다. 로스팅빈스를 아껴주시는 고객분들께 감사드립니다. 로스팅빈스 new 다크블루 홀빈 분쇄 200g 원두 커피 로스팅빈스 자체 로스팅한 커피로써 어두운 색깔에 어울리는 진한 느낌의 커피. 로스팅빈스 마블 디카프 홀빈 분쇄 200g 원두커피 견과류의 고소함과 호박같은 단맛이 `,
    loca: `http://www.loasting-beans.com`,
  },
  {
    title: `마이빈스 - 스타일`,
    text: `커들 랩 롱 원피스 45,800 마이빈스 로벰 퀼팅 미니 원피스 55,800 마이빈스 [마이빈스]로넬 플라워 롱 원피스 73,800 마이빈스 [마이빈스]런트 미니 원피스 31,800 마이빈스 초커 셔링 미니 원피스 47,800 마이빈스 시트론 트레이닝 팬츠 35,800 마이빈스 에리조 데님 팬츠 34,700 마이빈스 아델 백 스트랩 미니 원피스 54,900`,
    loca: `http://www.mybinstyle.net`,
  },
  {
    title: `빈스 블라우스`,
    text: `빈스 블라우스 / 빈스 블라우스로 캘리포니아의 자연스러운 우아함을 표현해보세요. 은은한 레이어링과 드레이핑 테크닉을 가미한 유려한 컷으로 여성의 몸매를 돋보이게 하는 시스루 블라우스를 구현했습니다. 모노톤과 절제된 색상의 매혹적인 실크 소재가 브랜드의 시그니처 스타일인 미니멀리즘을 강조합니다. 질감을 살린 디테일과 차분한 프린트, 섬세한 장식을 느껴보세요.`,
    loca: `https://www.farfrom.co.kr/kr/shopping/women/vince/blouses/items.aspx`,
  },
  {
    title: `자몽블랙티 1.2kg`,
    text: `... 전문 쇼핑몰입니다. 빈스업; 주원료명	상세페이지 참고 식품의 유형	상세페이지 참고 생산자 및 소재지	상세페이지 참고 영양성분 표시 대상 여부	N 유전자 재조합 식품 여부	N 특수용도식품 여부	N 수입식품 여부	N 표시광고 사전심의필	해당없음 제조연월일, 유통기한 또는 품질유지기한	생산 제조년월일이 상시 다르므로 소비자 상담번호로문의주시면 안내해 드리겠습니다.유통기한은 제조일로부터 3개월 이상 남은 제품만 출고하고있습니다. 함량	상세페이지 참고 원료원산지	상세페이지 참고 수입자	해당없음 영유아식 또는 체중조절식품 해당 여부	N`,
    loca: `http://www.soniomart.com/item/G2000032995`,
  },  
]

export {
  search_arr
}