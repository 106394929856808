import { useEffect, useRef } from "react";
import { v4 as uuidv4 } from "uuid";

import "./Search.css";
import { search_arr } from "../../contents/content_arr";

function Search() {
  const strongStr = (str) => {
    return "<b>" + str + "</b>";
  };

  const locatePage = (loca) => {
    window.location = loca;
  }

  const SearchResult = ({ title, text, loca, id = uuidv4(), keypoint }) => {
    const rootRef = useRef(null);

    useEffect(() => {
      if(keypoint) {
        let newTitle = title;
        let newText = text;

        keypoint.split(/ /).forEach(e => {
          newTitle = newTitle.replaceAll(e,strongStr(e));
          if (text) {
            newText = newText.replaceAll(e,strongStr(e));
          }
        })

        document.getElementById("sbb_head_id" + id).innerHTML = newTitle;
        if (text) {
          document.getElementById("sbb_head_t_id" + id).innerHTML = newText;
        }
      } else {
        document.getElementById("sbb_head_id" + id).innerHTML = title;
        if (text) {
          document.getElementById("sbb_head_t_id" + id).innerHTML = text;
        }
      }
    },[keypoint, title, text, id]);

    return (
      <div ref={rootRef} className="sb_block" onClick={() => locatePage(loca)}>
        <div className="sbb_head">
          <img src="/images/m-index-bl1.png" width="2" height="13" alt="g" />
          <div id={`sbb_head_id${id}`} className="sbb_head_t">
            {/* auto fill */}
          </div>
        </div>
        <div id={`sbb_head_t_id${id}`} className="sbb_text">
          {/* auto fill */}
        </div>
        <div className="sbb_loca">{loca}</div>
      </div>
    )
  }

  return (
    <div className="Search">
      <div className="Search_Container">
        <table cellPadding="3" cellSpacing="0" width="100%">
          <tbody>
            <tr height="33">
              <td></td>
              <td valign="bottom">
                <a href="/">
                  <img
                    src="/images/logo.png"
                    width="62"
                    height="23"
                    alt="Daum"
                  />
                </a>
              </td>
              <td valign="bottom" nowrap="">
                <a href="/">
                  <img
                    src="/images/ts_search.gif"
                    width="33"
                    height="23"
                    alt="검색"
                  />
                </a>
              </td>
              <td align="right" valign="bottom" width="100%">
                <table cellPadding="1" cellSpacing="0">
                  <tbody>
                    <tr>
                      <td style={{ fontSize: "9pt" }} nowrap="">
                        <a href="/">
                          <font style={{ fontSize: "9pt" }} color="#0000FF">
                            My
                          </font>
                        </a>
                        |
                        <a href="/">
                          <font style={{ fontSize: "9pt" }} color="#0000FF">
                            메일
                          </font>
                        </a>
                        |
                        <a href="/">
                          <font style={{ fontSize: "9pt" }} color="#0000FF">
                            카페
                          </font>
                        </a>
                        |
                        <a href="/">
                          <font style={{ fontSize: "9pt" }} color="#0000FF">
                            쇼핑
                          </font>
                        </a>
                        |
                        <a href="/">
                          <font style={{ fontSize: "9pt" }} color="#0000FF">
                            검색
                          </font>
                        </a>
                        |
                        <a href="/">
                          <font style={{ fontSize: "9pt" }} color="#0000FF">
                            메신저
                          </font>
                        </a>
                        |
                        <a href="/">
                          <font style={{ fontSize: "9pt" }} color="#0000FF">
                            아바타몰
                          </font>
                        </a>
                        |<font color="#0000ff">ㆍ</font>
                        <a href="/">
                          <font style={{ fontSize: "9pt" }} color="#0000FF">
                            로그인
                          </font>
                        </a>
                        |<font color="#0000ff">ㆍ</font>
                        <a href="/">
                          <font style={{ fontSize: "9pt" }} color="#0000FF">
                            전체보기
                          </font>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>

        <div id="search_top_border"> </div>

        <div id="search_top_adv">
          <a id="sta_left" href="/">
            <div className="fHyper fSize_16">미모도, 건강도 업그레이드!!</div>
            <font className="search_top_adv_b">
              두마리 토끼를 한번에 잡는다
            </font>
          </a>
          <div id="sta_center"></div>
          <a id="sta_right" href="/">
            <div className="fHyper fSize_16">소원을 말해봐</div>
            <font className="search_top_adv_b">노트북에 명품 가방이 공짜~</font>
          </a>
        </div>

        <table
          bgcolor="#E6E6E6"
          border="0"
          cellPadding="0"
          cellSpacing="0"
          width="100%"
        >
          <tbody>
            <tr>
              <td width="100%" height="20" className="search_basic">
                &nbsp;
                <a href="/">NETHINK</a> &gt; <b>검색</b>
              </td>
            </tr>
          </tbody>
        </table>

        <div className="mb8"></div>

        <table border="0" cellSpacing="0" cellPadding="0" width="100%">
          <input type="hidden" name="doc" value="host" />
          <tbody>
            <tr>
              <td bgcolor="#DBDBDB" colSpan="4" height="1"></td>
            </tr>
            <tr>
              <td bgcolor="#d9f0ff" colSpan="4" height="1"></td>
            </tr>
            <tr>
              <td colSpan="4" bgcolor="#DBDBDB" height="10">
                <img alt="s" src="/images/trans.png" width="1" height="1" />
              </td>
            </tr>
            <tr bgcolor="#DBDBDB">
              <td>
                <br />
              </td>
              <td>
                <table border="0" cellSpacing="0" cellPadding="0">
                  <tbody>
                    <tr>
                      <td>
                        <img
                          alt="s"
                          src="/images/m-search-bl1.png"
                          width="25"
                          height="22"
                          border="0"
                          hspace="3"
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="q"
                          size="30"
                          defaultValue={"쇼핑몰 빈스"}
                        />
                      </td>
                      <td>
                        <input
                          type="image"
                          src="/images/m-search-bu1.png"
                          width="35"
                          height="22"
                          border="0"
                          hspace="3"
                          alt="검색"
                        />
                      </td>
                      <td nowrap="">
                        &nbsp;&nbsp;
                        <a href="/" className="li_blue008">
                          통합검색 환경설정
                        </a>
                        <img
                          alt="s"
                          src="/images/m-search-bar1.gif"
                          width="2"
                          hspace="5"
                          height="13"
                          align="absmiddle"
                        />
                        <a href="/" className="li_blue008">
                          Live 검색
                        </a>
                        <img
                          alt="s"
                          src="/images/m-search-bar1.gif"
                          width="2"
                          hspace="5"
                          height="13"
                          align="absmiddle"
                        />
                        <a href="/" className="li_blue008">
                          홈페이지 등록
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>
                <br />
              </td>
              <td>
                <br />
              </td>
            </tr>
            <tr>
              <td colSpan="4" bgcolor="#DBDBDB" height="10">
                <img alt="s" src="/images/trans.png" width="1" height="1" />
              </td>
            </tr>
            <tr>
              <td width="25" nowrap="">
                <img
                  alt="s"
                  src="/images/m-search-bg1.png"
                  width="31"
                  height="26"
                />
              </td>
              <td
                width="100%"
                nowrap=""
                background="/images/m-search-bg2.png"
                className="txt_fff"
              >
                <input type="radio" name="qt" value="" defaultChecked />
                통합검색
                <input type="radio" name="qt" value="A" />
                디렉토리
                <input type="radio" name="qt" value="B" />
                웹문서
                <input type="radio" name="qt" value="C" />
                카페
                <input type="radio" name="qt" value="D" />
                이미지
                <input type="radio" name="qt" value="E" />
                MP3/사운드
                <input type="radio" name="qt" value="F" />
                동영상
                <input type="radio" name="qt" value="G" />
                뉴스
              </td>
              <td>
                <img
                  alt="s"
                  src="/images/m-search-bg3.png"
                  width="18"
                  height="26"
                />
              </td>
              <td width="140" nowrap="" background="/images/m-search-bg4.png">
                <img alt="s" src="/images/m-search-bg4.png" />
              </td>
            </tr>
            <tr>
              <td colSpan="4" height="5"></td>
            </tr>
          </tbody>
        </table>

        <div id="search_margin"></div>

        <div id="search_box">
          <div id="search_box_l">
            <table
              border="0"
              cellPadding="0"
              cellSpacing="0"
              width="180"
              style={{ border: "solid 1pixel #4395d5" }}
            >
              <tbody>
                <tr>
                  <td align="center" height="23" bgcolor="#878787">
                    <font>
                      <b style={{ fontSize: "9pt", color: "#fff" }}>
                        검색 키워드샵
                      </b>{" "}
                      <br />
                    </font>
                  </td>
                </tr>
                <tr style={{ lineHeight: "110%" }}>
                  <td bgcolor="#f7fcff" height="61" style={{ padding: "4pt" }}>
                    <font size="2">
                      <a href="/">
                        <font color="#0047b3">
                          <b>키워드를 팝니다~</b>
                        </font>
                      </a>
                      <br />
                      <a href="/" style={{ textDecoration: "none" }}>
                        원하는 <font color="#8a00b3">검색결과,</font>
                        <br />
                        원하는 <font color="#8a00b3">위치</font>에 링크하세요.
                      </a>
                    </font>
                  </td>
                </tr>
              </tbody>
            </table>

            <table
              border="0"
              cellPadding="0"
              cellSpacing="1"
              width="100%"
              bgcolor="#DBDBDB"
              style={{ fontSize: "10pt" }}
            >
              <tbody>
                <tr>
                  <td>
                    <table
                      border="0"
                      cellPadding="0"
                      cellSpacing="0"
                      width="100%"
                    >
                      <tbody>
                        <tr>
                          <td bgcolor="#ffffff">
                            <table
                              border="0"
                              cellPadding="0"
                              cellSpacing="0"
                              width="100%"
                            >
                              <tbody>
                                <tr>
                                  <td
                                    background="/images/b01_menu.png"
                                    height="5"
                                  ></td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                        <tr>
                          <td bgcolor="#ffffff">
                            <table
                              border="0"
                              cellPadding="1"
                              cellSpacing="1"
                              width="100%"
                            >
                              <tbody>
                                <tr>
                                  <td bgcolor="#BDBDBD" height="22">
                                    &nbsp;{" "}
                                    <font color="#0e49a3">
                                      <b>추천 사이트</b>
                                    </font>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <table
                              border="0"
                              cellPadding="6"
                              cellSpacing="1"
                              width="100%"
                              bgcolor="#f7fcff"
                            >
                              <tbody>
                                <tr>
                                  <td>
                                    <table
                                      border="0"
                                      cellPadding="0"
                                      cellSpacing="0"
                                      width="100%"
                                    >
                                      <tbody>
                                        <tr>
                                          <td width="10" align="left"></td>
                                          <td>
                                            <a href="/" className="li_blue02b">
                                              {" "}
                                              오늘의 선택
                                            </a>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td width="10">
                                            <br />
                                          </td>
                                          <td>
                                            <font color="#546693">
                                              봄날이 무섭다
                                              <br />
                                              알레르기성 비염 &gt;.&lt;{" "}
                                              <font>
                                                <br />
                                                <img
                                                  src="/images/trans.png"
                                                  width="1"
                                                  height="3"
                                                  alt="s"
                                                />
                                                <br />
                                              </font>
                                            </font>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td width="10" align="left"></td>
                                          <td>
                                            <a href="/" className="li_blue02b">
                                              {" "}
                                              웹사이트 대박
                                            </a>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td width="10">
                                            <br />
                                          </td>
                                          <td>
                                            <font color="#546693">
                                              도서, 전자제품 구입을
                                              <br />
                                              가장 저렴한 가격으로
                                            </font>
                                            <br />
                                            <img
                                              src="/images/trans.png"
                                              width="1"
                                              height="3"
                                              alt="s"
                                            />
                                            <br />
                                          </td>
                                        </tr>
                                        <tr>
                                          <td width="10" align="left"></td>
                                          <td>
                                            <a href="/" className="li_blue02b">
                                              {" "}
                                              HOT! 이슈
                                            </a>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td width="10">
                                            <br />
                                          </td>
                                          <td>
                                            <font color="#546693">
                                              아카데미 시상식~
                                              <br />그 짜릿한 결과 발표~!!
                                            </font>
                                            <br />
                                            <img
                                              src="/images/trans.png"
                                              width="1"
                                              height="3"
                                              alt="s"
                                            />
                                            <br />
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                        <tr>
                          <td height="1" bgcolor="#DBDBDB"></td>
                        </tr>
                        <tr>
                          <td bgcolor="#ffffff">
                            <table
                              border="0"
                              cellPadding="1"
                              cellSpacing="1"
                              width="100%"
                            >
                              <tbody>
                                <tr>
                                  <td bgcolor="#BDBDBD" height="22">
                                    &nbsp;
                                    <img
                                      src="/images/bullet1.gif"
                                      width="10"
                                      height="11"
                                      align="absmiddle"
                                      alt="s"
                                    />{" "}
                                    <a href="/" className="li_blue04a">
                                      인기검색어 TOP50
                                    </a>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <table
                              border="0"
                              cellPadding="6"
                              cellSpacing="1"
                              width="100%"
                              bgcolor="#f7fcff"
                            >
                              <tbody>
                                <tr>
                                  <td className="search_left_li">
                                    <a href="/" className="li_blue02b">
                                      복스뮤직
                                    </a>
                                    <br />
                                    <a href="/" className="li_blue02b">
                                      세오클럽
                                    </a>
                                    <br />
                                    <a href="/" className="li_blue02b">
                                      다다모임
                                    </a>
                                    <br />
                                    <a href="/" className="li_blue02b">
                                      마블넷
                                    </a>
                                    <br />
                                    <a href="/" className="li_blue02b">
                                      아이러브
                                    </a>
                                    <br />
                                    <a href="/" className="li_blue02b">
                                      두게임
                                    </a>
                                    <br />
                                    <a href="/" className="li_blue02b">
                                      스포츠한국
                                    </a>
                                    <br />
                                    <a href="/" className="li_blue02b">
                                      크아케이드
                                    </a>
                                    <br />
                                    <a href="/" className="li_blue02b">
                                      한국카드
                                    </a>
                                    <br />
                                    <a href="/" className="li_blue02b">
                                      버스
                                    </a>
                                    <br />
                                    <a href="/" className="li_blue02b">
                                      서울스포츠
                                    </a>
                                    <br />
                                    <a href="/" className="li_blue02b">
                                      강용준
                                    </a>
                                    <br />
                                    <a href="/" className="li_blue02b">
                                      스포츠일간
                                    </a>
                                    <br />
                                    <a href="/" className="li_blue02b">
                                      제이은행
                                    </a>
                                    <br />
                                    <a href="/" className="li_blue02b">
                                      명랑소녀
                                    </a>
                                    <br />
                                    <div align="right">
                                      <img
                                        src="/images/m-more-arrow.gif"
                                        width="9"
                                        height="9"
                                        hspace="3"
                                        align="absmiddle"
                                        alt="s"
                                      />
                                      <a href="/" className="li_rede90">
                                        more
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                        <tr>
                          <td height="1" bgcolor="#DBDBDB"></td>
                        </tr>

                        <tr>
                          <td bgcolor="#ffffff">
                            <table
                              border="0"
                              cellPadding="1"
                              cellSpacing="1"
                              width="100%"
                            >
                              <tbody>
                                <tr>
                                  <td
                                    background="/images/b01_menu.png"
                                    height="5"
                                  ></td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div id="search_box_r">
            <table border="0" cellSpacing="0" cellPadding="0" width="100%" style={{marginBottom: "12px"}}>
              <tbody>
                <tr>
                  <td
                    style={{width: "223px", height: "25px"}}
                    background="/images/bim08_fire.png"
                    nowrap=""
                  >
                    {" "}
                    &nbsp;
                    <font size="3">
                      <b style={{letterSpacing: "5px", paddingLeft: "10px"}}>웹 문서</b>
                    </font>
                  </td>
                  <td
                    align="right"
                    background="/images/bg.png"
                    style={{width: "calc(100% - 223px)"}}
                  ></td>
                </tr>
              </tbody>
            </table>

            {
              search_arr.map((e,i) => {
                return (
                  <SearchResult
                    key={`search_result_${i}`}
                    title={e.title || "title"}
                    text={e.text || "text"}
                    loca={e.loca || "loca"}
                    keypoint={"쇼핑몰 빈스"}
                  />
                )
              })
            }
            
            <div className="search_more">{`검색결과 더보기 >`}</div>
          </div>
        </div>

        {/* end */}

        <div id="home_footer">
          <table border="0" cellPadding="1" cellSpacing="0" align="center">
            <tbody>
              <tr>
                <td width="100%" align="center">
                  <font size="2">
                    <a href="/">
                      <b>서비스 이용문의</b></a> -
                    <a href="/">
                      서비스 약관</a> -
                    <a href="/">
                      개인정보 보호정책
                    </a> -
                    <a href="/">
                      스팸방지정책</a> -
                    <a href="/">
                      <b>회사소개</b></a>
                    (<a href="/">
                      Company
                      Info</a>) -
                    <a href="/">
                      사원모집</a> -
                    <a href="/">
                      제휴문의</a>
                  </font>
                </td>
              </tr>
              <tr>
                <td width="100%" align="center">
                  <font size="2">
                    <a href="/">
                      광고안내</a> -
                    <a href="/">
                      온라인우표제</a> -
                    <a href="/">
                      호스팅서비스</a> -
                    <a href="/">
                      홈페이지등록</a> -
                    <a href="/">
                      알리미등록</a> -
                    <a href="/">
                      기업구인등록</a>
                  </font>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Search;
