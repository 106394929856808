import './Home.css';

function Home() {
  // func
  const goSearchPage = () => {
    window.location = `/search?query=%EC%87%BC%ED%95%91%EB'%AA%B0%20%EB%B9%88%EC%8A%A4`
  }

  const keyDown = (e) => {
    if(e.keyCode === 13) {
      goSearchPage();
    }
  }

  // comp
  const HmlBot = ({ t }) => {
    return (
      <div className="hml_bot">
        <img src="/images/black.gif" alt="black_dot" />
        <div className="hml_bot_t">{t}</div>
      </div>
    )
  }

  const HrmBoxListR = ({ t, end }) => {
    return (
      <div className={!end ? "hrm_box_list_r" : "hrm_box_list_r mb8"}>
        <div>
          {"- "}
          <span>{t}</span>
        </div>
      </div>
    )
  }

  const HrmBoxList = ({ title, arr }) => {
    const len = arr.length - 1;

    return (
      <div className="hmr_box_list">
        <div className="hrm_box_list_l">
          {title}
        </div>
        <div className="hrm_box_list_rr">
          {
            arr.map((e,i) => {
              return (
                <HrmBoxListR t={e} end={len - i} key={`HrmBoxListR_key_${title}_${i}`} />
              )
            })
          }
        </div>
      </div>
    )
  }

  return (
    <div className="Home">
      <div className="Container">
        <table width="780" height="70" valign="top">
          <tbody>
            <tr>
              <td width="289" align="center" valign="middle"></td>
              <td width="169" align="center">
                <img src="/images/logo.png" width="130" height="48" alt="Daum" />
              </td>
              <td width="289" align="center" valign="middle">
                <font size="3">
                  <font className="fHyper fSize_16">어린이 영어학습</font>
                  <br/>
                  <font size="2">처음 선택이 평생을 좌우한다</font>
                </font>
              </td>
            </tr>
          </tbody>
        </table>

        <table
          id="home_header_menu"
          border="0"
          cellSpacing="0"
          cellPadding="0"
          width="780"
        >
          <tbody>
            <tr>
              <td>
                <table border="0" cellPadding="0" cellSpacing="1" width="100%">
                  <tbody>
                    <tr>
                      <td
                        // bgcolor="#FECE00"
                        bgcolor="#36BC9B"
                        width="25%"
                        height="17"
                        align="center"
                      >
                        <font className="home_header_menu">메일</font>
                      </td>
                      <td
                        // bgcolor="#FE8C14"
                        bgcolor="#FF93AC"
                        width="25%"
                        height="17"
                        align="center"
                      >
                        <font className="home_header_menu">카페</font>
                      </td>
                      <td
                        // bgcolor="#8CCA2D"
                        bgcolor="#36BC9B"
                        width="25%"
                        height="17"
                        align="center"
                      >
                        <font className="home_header_menu">쇼핑</font>
                      </td>
                      <td
                        // bgcolor="#4DAACE"
                        bgcolor="#FF93AC"
                        width="25%"
                        height="17"
                        align="center"
                      >
                        <font className="home_header_menu">검색</font>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>

        <div className="w780" id="header_high">
          <div className="home_header_high_1">
            <div className="fSize_13" style={{borderBottom: "solid 2px #ccc", paddingBottom: "1px"}}>
              {"※ "}
              <span className="fHyper" style={{color: "#000"}}>기획 / 특집</span>
            </div>

            <div style={{paddingLeft: "8px"}}>
              <div className="fHyper fSize_13" style={{marginTop: "8px", marginBottom: "8px"}}>
                이주노동자, 조선족의 한국
              </div>

              <div className="fSize_13 hh_left">
                {"- "}
                <span className="hBasic">모두함께 살기위해 우리는?</span>
              </div>
              <div className="fSize_13 hh_left">
                {"- "}
                <span className="hBasic">박정윤 교수 인터뷰</span>
              </div>
              <div className="fSize_13 hh_left">
                {"- "}
                <span className="hBasic">연변개그, 개그는 개그일뿐?</span>
              </div>
            </div>
          </div>

          <div className="header_high_c">
            <td width="572" valign="top">
              <table border="0" cellPadding="2" cellSpacing="1" width="100%">
                <tbody>
                  <tr>
                    <td nowrap="">
                      <font size="2">생활 :</font>
                    </td>
                    <td nowrap="">
                      <font size="2">
                        <a href="/"><b>취업</b></a>
                        <font className="red" color="#ff0000"><b>up</b></font>{" · "}
                        <a href="/">요리</a>{" · "}
                        <a href="/">사진</a>
                        <font className="red" color="#ff0000"><b>up</b></font>{" · "}
                        <a href="/">부동산</a>{" · "}
                        <a href="/">자동차</a>{" · "}
                        <a href="/">여행</a>{" · "}
                        <a href="/">페이지</a>{" · "}
                        <a href="/">다이어트</a>
                        <font className="red" color="#ff0000"><b>new</b></font>{" · "}
                        <a href="/">임신/육아</a>
                      </font>
                    </td>
                  </tr>
                  <tr>
                    <td nowrap="">
                      <font size="2">정보 :</font>
                    </td>
                    <td nowrap="">
                      <font size="2">
                        <a href="/">뉴스</a> {" · "}
                        <a href="/">월드컵</a> {" · "}
                        <a href="/">영어사전</a> {" · "}
                        <a href="/">책</a> {" · "}
                        <a href="/">컴퓨터</a> {" · "}
                        <a href="/">매거진</a> {" · "}
                        <a href="/">교육</a>{" "}
                        <img
                          src="/images/arrow_i.gif" alt="arrow_i"
                          width="3" height="5" />
                        {" "}
                        <a href="/" className="home_header_s">영어학습</a>{" · "}
                        <a href="/" className="home_header_s">금융자격</a>{" · "}
                        <a href="/" className="home_header_s">유학</a> {" · "}
                        <a href="/"><b>대입</b></a>
                      </font>
                    </td>
                  </tr>
                  <tr>
                    <td nowrap="">
                      <font size="2">오락 :</font>
                    </td>
                    <td nowrap="">
                      <font size="2">
                        <a href="/">뮤직</a> {" · "}
                        <a href="/">게임</a> {" · "}
                        <a href="/">만화</a> {" · "}
                        <a href="/">애니</a> {" · "}
                        <a href="/">영화</a> {" · "}
                        <a href="/">상영관</a> {" · "}
                        <a href="/">인터넷방송</a> {" · "}
                        <a href="/"><b>운세</b></a> {" · "}
                        <a href="/">스포츠</a> {" · "}
                        <a href="/">네티즌펀드</a> {" · "}
                        <a href="/">복권</a>
                      </font>
                    </td>
                  </tr>
                  <tr>
                    <td nowrap="">
                      <font size="2">거래 :</font>
                    </td>
                    <td nowrap="">
                      <font size="2">
                        <a href="/">쇼핑</a> {" · "}
                        <a href="/">경매</a> {" · "}
                        <a href="/">공동구매</a> {" · "}
                        <a href="/">상품권</a> {" · "}
                        <a href="/">카드</a> {" · "}
                        <a href="/">메일뱅킹</a> {" · "}
                        <a href="/"><b>금융플라자</b></a>{" "}
                        <img
                          src="/images/arrow_i.gif" alt="arrow_i"
                          width="3" height="5" />
                        {" "}
                        <a href="/" className="header_small">증권</a>{" · "}
                        <a href="/" className="header_small">뱅크</a>{" · "}
                        <a href="/" className="header_small">보험</a>
                      </font>
                    </td>
                  </tr>
                  <tr>
                    <td nowrap="">
                      <font size="2">만남 :</font>
                    </td>
                    <td nowrap="">
                      <font size="2">
                        <a href="/">카드메일</a> {" · "}
                        <a href="/">사람찾기</a> {" · "}
                        <a href="/">칼럼</a> {" · "}
                        <a href="/"><b>메신저</b></a>
                        <font className="red" color="#ff0000"><b>up</b></font> {" · "}
                        <a href="/"><b>무선인터넷</b></a> {" · "}
                        <a href="/">무선메시지</a>
                        <font className="red" color="#ff0000"><b>up</b></font> {" · "}
                        <a href="/">인터넷폰</a> {" · "}
                        <a href="/">전체</a>
                        <font size="1" color="#0000FF">▶</font>
                      </font>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </div>
        </div>

        <table bgcolor="#B1B1B1" border="0" cellPadding="1" cellSpacing="0" width="780" height="24" align="center" valign="top">
          <input type="hidden" name="u" value="host" />
          <tbody>
            <tr>
              <td>
                <table bgcolor="#D7D7D7" border="0" cellPadding="2" cellSpacing="0" height="24" width="100%">
                  <tbody>
                    <tr>
                      <td width="280" align="right" nowrap="">
                        <font size="2">
                          <input type="radio" defaultChecked />
                          <span>통합검색 </span>
                          <input type="radio"  />
                          <span>이미지 </span>
                          <input type="radio"  />
                          <span>사운드 </span>
                          <input type="radio"  />
                          <span>카페 </span>
                          &nbsp;&nbsp;
                        </font>
                      </td>
                      <td align="center" nowrap="">
                        <font size="2">
                          <img
                            src="/images/i_search.gif" alt='n'
                            width="14" height="14" />
                          <font color="#000000" style={{margin: "0 8px 0 4px"}}>
                            <b style={{textDecoration: "underline"}}>검색</b>
                          </font>
                          <font size="3" face="굴림체">
                            {/* main */}
                            <input onKeyDown={keyDown} id="home_search" type="text" size="22" name="q" autoComplete="off" spellCheck="false" />
                          </font>
                          <input type="image" onClick={goSearchPage}
                            src="/images/bu_search.gif"
                            border="0" width="34" height="20" hspace="2" alt="검색" align="absmiddle" />
                        </font>
                      </td>
                      <td align="center" width="200" nowrap="">
                        <font size="2">
                          <a href="/">알뜰하고 풍성한 결혼준비</a>
                        </font>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>

        <div id="home_main" className="w780">
          <div id="home_main_l">
            <table border="0" cellPadding="1" cellSpacing="0" width="200px" align="center">
              <tbody>
                <tr>
                  <td colSpan="3" height="7"></td>
                </tr>
                <tr>
                  <td align="right" nowrap="">
                    <font size="2">아이디</font>
                  </td>
                  <td>
                    <font size="3" face="굴림체"><input type="text" name="id" size="8" /></font>
                  </td>
                  <td>
                    
                  </td>
                </tr>
                <tr>
                  <td align="right" nowrap="">
                    <font size="2">비밀번호</font>
                  </td>
                  <td>
                    <font size="3" face="굴림체">
                      <input type="password" name="pw" size="8" maxLength="8" />
                    </font>
                  </td>
                  <td align="left">
                    <input type="image"
                      src="/images/bu_login.gif"
                      border="0" width="46" height="20" alt="로그인" />
                  </td>
                </tr>
                <tr>
                  <td colSpan="3" align="center" valign="top">
                    <font size="2">
                      <input type="radio" name="loginmode" value="normal" defaultChecked />
                      <a
                        href="/"
                        className="login2">일반 접속</a>
                      <input type="radio" name="loginmode" value="ssl" /><a
                        href="/"
                        className="login2">보안 접속</a>
                    </font>
                  </td>
                </tr>
                <tr>
                  <td colSpan="3" align="center" valign="center" height="25">
                    <font size="2">
                      <a href="/" className="mylink">회원가입</a>
                      <font size="3"><b> l </b></font>
                      <a href="/" className="mylink">My 페이지</a>
                    </font>
                  </td>
                </tr>
              </tbody>
            </table>

            <div>
              <div className="hml_top">이벤트</div>
              <HmlBot t={"블랙호크 무료시사회"} />
              <HmlBot t={"IT전문가=국제공인자격자!"} />
              <HmlBot t={"매일100명 가족사진 무료로"} />
              <HmlBot t={"5발 쏘아서 3발만 맞추면!"} />
              <HmlBot t={"퍼펙트 러브투어!"} />

              <div className="hml_top">뉴스 포커스</div>
              <HmlBot t={"美, 20년래 국방예산 최대"} />
              <HmlBot t={"국민연금, 1천800억 '낮잠'"} />
              <HmlBot t={"베트남, 개고기 논쟁 한국편"} />
              <HmlBot t={"이통료 10% ↓ 전망! ^^"} />
              <HmlBot t={"카드시장 '戰國시대'"} />

              <div className="hml_top">공지사항</div>
              <HmlBot t={"심심한데 게임 한판 어때?"} />
              <HmlBot t={"박지영과의 1일 데이트"} />
              <HmlBot t={"코리안 힙합 어워즈 2002"} />
              <HmlBot t={"인연은 언제쯤 나타날까요?"} />
            </div>

          </div>

          <div id="home_main_r">
            <div className="hmr_top">즐거운 삶, 유익한 정보</div>
            <div className="hmr_box">
              <div className="hmr_box_l">
                <HrmBoxList
                  title={"교육"}
                  arr={[
                    `"엄마! 저 책사줘요" 우리 아이 창의력 쑥쑥!`,
                  ]}
                />

                <HrmBoxList
                  title={"운세"}
                  arr={[
                    `'아직도' 토정비결 안보셨어요? 공짜에요~`,
                  ]}
                />

                <HrmBoxList
                  title={"공개자료실"}
                  arr={[
                    `자료 다운받으러 딴데 가지 말자!`,
                  ]}
                />

                <HrmBoxList
                  title={"노래방"}
                  arr={[
                    `지금 플래티넘 노래방이 공짜!!`,
                  ]}
                />

                <div className="hmr_box_line">CD 대폭발! 일주일 남았습니다! 오픈기념 이벤트~!!!</div>
              </div>

              <div className="hmr_box_r">
                <table bgcolor="#C4C4C4" border="0" cellPadding="1" cellSpacing="0" width="175">
                  <tbody>
                    <tr>
                      <td>
                        <table bgcolor="#F5F5F5" border="0" cellPadding="4" cellSpacing="0" width="100%" height="55">
                          <tbody>
                            <tr>
                              <td valign="top" width="45">
                                <a
                                  href="/">
                                  <img
                                    src="/images/genie.png"
                                    width="45" height="45" border="0" alt='n'/>
                                </a>
                              </td>
                              <td align="left">
                                <font size="2">
                                  <a
                                    href="/">
                                    소원을말해봐</a>
                                  <br/>
                                  노트북에 명품 가방이 공짜~
                                </font>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div style={{display:"flex", alignItems: "center", justifyContent: "center", padding: "2px 0"}}>
                          <a href="/">무료로 이벤트 참여하기</a>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="hmr_box hmr_box_2">
              <div className="hmr_box_l">
                <HrmBoxList
                  title={"학자금대출"}
                  arr={[
                    `중국문화연수, 상품권, 장학금까지!`,
                  ]}
                />

                <HrmBoxList
                  title={"IT 교육"}
                  arr={[
                    ` 웹전문가(웹디자이너,웹마스터..) 되는 법`
                  ]}
                />

                <HrmBoxList
                  title={"영어학습"}
                  arr={[
                    `24시간 즐거운 영어회화 (네이티브와 함께)`
                  ]}
                />

                <HrmBoxList
                  title={"인터넷방송"}
                  arr={[
                    `트랜스젠더들의 삶을... 이 여자가 사는법`
                  ]}
                />

                <div className="hmr_box_line">[중국어] 2008년 올림픽이 열리는 나라 중국으로 가는길</div>
              </div>

              <div className="hmr_box_r">
                <table border="0" cellPadding="0" cellSpacing="1" bgcolor="#217dd2" width="173">
                  <tbody>
                    <tr>
                      <td>
                        <table border="0" cellPadding="5px" cellSpacing="1" bgcolor="#ffffff" width="100%">
                          <tbody>
                            <tr>
                              <td>
                                <img
                                  src="/images/bullet1.gif"
                                  width="13" height="13" border="0" align="absmiddle" alt='n'/>
                                <a href="/">
                                  <font size="2" color="#125faf">미아찾기 조인트 콘서트</font>
                                </a>
                                <br/>
                                <br/>
                                <img
                                  src="/images/bullet1.gif"
                                  width="13" height="13" border="0" align="absmiddle" alt='n'/>
                                <a href="/">
                                  <font size="2" color="#125faf">캠페인 신청하기</font>
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="hmr_top">생활의 지혜
              <span> - </span>
              <span className="hmr_top_btn">쇼핑</span>
              <span> / </span>
              <span className="hmr_top_btn">공동구매</span>
              <span> / </span>
              <span className="hmr_top_btn">경매</span>
              <span> / </span>
              <span className="hmr_top_btn">백화점 쇼핑몰</span>
            </div>

            <div className="hmr_box">
              <div className="hmr_box_l">
                <HrmBoxList
                  title={"쇼핑 제안"}
                  arr={[
                    `개그 지갑, 가방, 벨트로 패션 감각을 살리세요!`,
                    `리버버, 가모페라, 뜨로에, 구조 - 명품 할인전`,
                  ]}
                />

                <HrmBoxList
                  title={"오늘의 상품"}
                  arr={[
                    `토끼 / 마로 인형 세트 (12,000원)`,
                    `디지털카메라전 - DG800K (180,000원)`,
                  ]}
                />

                <div className="hmr_box_line">선물고민 끝!! - 선호도 1위 선물목록 총집합!!</div>
              </div>

              <div className="hmr_box_r">
                <table bgcolor="#C4C4C4" border="0" cellPadding="1" cellsPacing="0" width="175">
                  <tbody>
                    <tr>
                      <td>
                        <table bgcolor="#F5F5F5" border="0" cellPadding="4" cellsPacing="0" width="100%">
                          <tbody>
                            <tr>
                              <td>
                                <font size="2">
                                  <a href="/">
                                      [2002년의 화두]
                                    </a>
                                </font>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <font size="2">
                                  -금연/ 다이어트/ 어학학습<br/>
                                  -사랑하면 금연을 선물하자
                                </font>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="hmr_box hmr_box_2">
              <div className="hmr_box_l">
                <HrmBoxList
                  title={"재테크정보"}
                  arr={[
                    `( 투자 전략 과 증권주 종목{中} )`,
                    `[증권방송] 대박의 꿈을 현실로!`
                  ]}
                />

                <HrmBoxList
                  title={"금융제안"}
                  arr={[
                    `[신용대출] 요즘 금리가 낮아서`,
                    `[모의투자몰] 배당은 짜릿! 재미는 솔솔~~`,
                    `프리미엄 메일 서비스를 공짜로 받으세요~`
                  ]}
                />

                <HrmBoxList
                  title={"카드"}
                  arr={[
                    `야구, 영화, 놀이공원 딱 좋아 / 한편 활쏘기는?`
                  ]}
                />

                <div className="hmr_box_line">하나!둘! 하나!둘! 나의 금융기관들 헤쳐모여라 - 머니투게더</div>
              </div>

              <div className="hmr_box_r">
                <table bgcolor="#C4C4C4" border="0" cellPadding="1" cellSpacing="0" width="175">
                  <tbody>
                    <tr>
                      <td>
                        <table bgcolor="#F5F5F5" border="0" cellPadding="4" cellSpacing="0" width="100%" height="55">
                          <tbody>
                            <tr>
                              <td valign="top" width="45">
                                <a
                                  href="/">
                                  <img
                                    src="/images/45creditinfo.gif"
                                    width="45" height="45" border="0" alt='n'/>
                                </a>
                              </td>
                              <td align="left">
                                <font size="2">
                                  <a
                                    href="/">
                                    신용정보조회</a>
                                  <br/>
                                  내신용도는100점!
                                  <br/>
                                  네신용도는몇점?
                                </font>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="hmr_top">풍요로운 공동체
            </div>

            <div className="hmr_box">
              <div className="hmr_box_l">
                <HrmBoxList
                  title={"카페포커스"}
                  arr={[
                    `팬클럽 카페 없는 스타는 있을 수 없다!?`,
                    `카페 속보 게시판`
                  ]}
                />

                <HrmBoxList
                  title={"추천 칼럼"}
                  arr={[
                    `김찬욱 감독의 신작! 나쁜남자에 대하여`,
                    `시/네/티/즌`
                  ]}
                />

                <div className="hmr_box_line">[착한 마니또 되기] 보이지 않는 '정'을 보여 주세요!</div>
              </div>

              <div className="hmr_box_r">
                <table bgcolor="#C4C4C4" border="0" cellPadding="1" cellSpacing="0" width="175">
                  <tbody>
                    <tr>
                      <td>
                        <table bgcolor="#F5F5F5" border="0" cellPadding="4" cellSpacing="0" width="100%">
                          <tbody>
                            <tr>
                              <td>
                                <font size="2">
                                  <a href="/">
                                    ■□ 칼라폰 사용자 주목!
                                  </a>
                                  <br/>
                                  멀티팩 카페 가지세요
                                  <br/>
                                </font>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <font size="2">
                                  <a href="/">▷ 알리미 2차개편 ◁</a><br/>
                                  아이템몰, 이미지배너 추가<br/>
                                </font>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div id="home_footer">
          <table border="0" cellPadding="1" cellSpacing="0" align="center">
            <tbody>
              <tr>
                <td width="100%" align="center">
                  <font size="2">
                    <a href="/">
                      <b>서비스 이용문의</b></a> -
                    <a href="/">
                      서비스 약관</a> -
                    <a href="/">
                      개인정보 보호정책
                    </a> -
                    <a href="/">
                      스팸방지정책</a> -
                    <a href="/">
                      <b>회사소개</b></a>
                    (<a href="/">
                      Company
                      Info</a>) -
                    <a href="/">
                      사원모집</a> -
                    <a href="/">
                      제휴문의</a>
                  </font>
                </td>
              </tr>
              <tr>
                <td width="100%" align="center">
                  <font size="2">
                    <a href="/">
                      광고안내</a> -
                    <a href="/">
                      온라인우표제</a> -
                    <a href="/">
                      호스팅서비스</a> -
                    <a href="/">
                      홈페이지등록</a> -
                    <a href="/">
                      알리미등록</a> -
                    <a href="/">
                      기업구인등록</a>
                  </font>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

      </div>
    </div>
  );
}

export default Home;